<template>
  <div class="customer">
    <v-row>
      <v-col>
        <bread-crumb :items="breadcrumbs" />
      </v-col>
    </v-row>

    <v-card id="index">
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.path"
          :href="'#' + tab.path"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item value="general">
          <general-details></general-details>
        </v-tab-item>

        <v-tab-item value="system">
          <system-settings></system-settings>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiApplicationCog, mdiInformationOutline, mdiAccountMultiple, mdiAdjust,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import hasPermission from '@/mixins/hasPermission'
import SystemSettings from './SystemSettings.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import GeneralDetails from './GeneralDetails.vue'

export default {
  components: {
    SystemSettings,
    BreadCrumb,
    GeneralDetails,
  },
  mixins: [hasPermission],
  data() {
    return {
      resellerID: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'super-admin-dashboard' },
        },
        {
          text: 'Resellers',
          disabled: false,
          to: { name: 'resellers' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'General Details', path: 'general', icon: mdiAccountOutline },
      { title: 'Settings', path: 'system', icon: mdiApplicationCog },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiAccountMultiple,
        mdiAdjust,
      },
    }
  },
  mounted() {
    if (this.resellerID) {
      this.breadcrumbs.push(
        {
          text: this.resellerID,
          disabled: true,
          to: { name: 'reseller-details' },
        },
      )
    }
  },
}
</script>
