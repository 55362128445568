<template>
  <v-card>
    <v-card-title>
      Unresolved Transactions
    </v-card-title>
    <v-row
      justify="center"
      class="mb-0"
    >
      <v-col class="col-md-6 text-center">
        <search-input
          v-model="search"
          :filters="searchFilters"
          :active-filter="activeSearchFilter"
          @filter-change="onSearchFilterChange"
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :loading="isTransactionsLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear
          indeterminate
          class="mt-2"
          rounded
        ></v-progress-linear>
        <p class="text-center mt-1">
          Loading...Please wait
        </p>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="transactions.length > 0">
          <tr
            v-for="transaction in items"
            :key="transaction.transaction_reference"
          >
            <td>
              <span class="primary--text font-weight-medium">{{ transaction.account_number }}</span>
            </td>
            <td>
              {{ transaction.phone_number }}
            </td>
            <td>{{ transaction.transaction_reference }}</td>
            <td>Ksh {{ transaction.amount | formatCurrency }}</td>
            <td>{{ transaction.reason | formatReason }}</td>
            <td>{{ transaction.transaction_time | formatDate }}</td>
            <td @click.stop>
              <v-btn
                class="ma-2"
                small
                depressed
                color="primary"
                @click="showAssignTransactionDialog(transaction.id)"
              >
                <v-icon
                  left
                  size="16"
                >
                  {{ icons.mdiClipboardAccount }}
                </v-icon>
                Assign
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-8"
                    v-bind="attrs"
                    text
                    icon
                    color="red lighten-2"
                    @click="showDeleteTransactionDialog(transaction.id)"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-show="!isTransactionsLoading">
            <td
              :colspan="headers.length"
              style="text-align: center"
            >
              No transactions found
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-divider />
    <data-table-pagination
      :page="pagination.page"
      :page-count="pagination.pageCount"
      @page-change="onPageChange"
      @items-per-page-change="onItemsPerPageChange"
    />
    <assign-transaction-dialog
      :id="id"
      :show-dialog="assignTransactionDialog"
      :reseller-id="resellerId"
      @close="assignTransactionDialog = false"
      @transaction-assigned="getTransactions()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :message="'Are you sure you want to delete this transaction?'"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteTransaction()"
    />
  </v-card>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiClipboardAccount,
} from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import AssignTransactionDialog from '@/components/dialogs/AssignTransactionDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    SearchInput,
    AssignTransactionDialog,
    ConfirmDialog,
    DataTablePagination,
  },
  filters: {
    formatReason(value) {
      let reason
      switch (value) {
        case 0:
          reason = 'Account number not found'

          break
        case 1:
          reason = 'Meter reading not found'

          break
        case 2:
          reason = 'Customer not found'

          break

        default:
          reason = 'Unknown'
          break
      }

      return reason
    },
  },
  props: {
    resellerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sortBy: ['unresolved_mpesa_transactions.created_at'],
      sortDesc: [true],
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: 'Account Number', value: 'account_number' },
        {
          text: 'Phone number',
          value: 'phone_number',
          sortable: false,
        },
        {
          text: 'Reference',
          value: 'transaction_reference',
        },
        { text: 'Amount', value: 'amount' },
        { text: 'Reason', value: 'reason' },
        { text: 'Transaction Date', value: 'transaction_time' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'mpesa_transactions.BillRefNumber',
      searchFilters: [
        {
          text: 'Account number',
          value: 'mpesa_transactions.BillRefNumber',
        },
        {
          text: 'Phone number',
          value: 'mpesa_transactions.MSISDN',
        },
        {
          text: 'Reference',
          value: 'mpesa_transactions.TransID',
        },
        {
          text: 'Amount',
          value: 'mpesa_transactions.TransAmount',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiClipboardAccount,
      },
      id: '',
      isTransactionsLoading: false,
      assignTransactionDialog: false,
      transactions: [],
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Transactions',
          disabled: true,
          to: { name: 'transactions' },
        },
      ],
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getTransactions()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getTransactions()
      }
    },
  },
  mounted() {
    this.getTransactions()
  },
  methods: {
    getTransactions: _.debounce(function () {
      this.isTransactionsLoading = true
      axios
        .get(`unresolved-transactions?sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`)
        .then(response => {
          this.transactions = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isTransactionsLoading = false
        })
        .catch(error => {
          this.isTransactionsLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    showDeleteTransactionDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteTransaction() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`unresolved-transactions/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Transaction successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getTransactions()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    showAssignTransactionDialog(id) {
      this.id = id
      this.assignTransactionDialog = true
    },
    onTransactionClick(transactionId) {
      this.$router.push({ name: 'transaction-details', params: { id: transactionId } })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getTransactions()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getTransactions()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
  },
}
</script>

<style scoped>
.invalidAccountNumber {
  border-left: 1px solid #ffaa2c;
}
.noMeterReading {
  border-left: 1px solid #ff4c51;
}
.invalidCustomer {
  border-left: 1px solid #ed2cff;
}
</style>
