<template>
  <div class="general">
    <v-card>
      <v-card-text v-if="can('statistics-list')">
        <v-row v-show="statisticsDataLoading">
          <statistics-shimmer
            v-for="item in [0, 1, 2, 3]"
            :key="item"
          />
        </v-row>
        <v-row v-show="!statisticsDataLoading">
          <v-col
            v-for="data in statisticsData"
            :key="data.title"
            cols="6"
            md="3"
          >
            <v-card
              class="d-flex align-center"
              width="95%"
              hover
              ripple
              flat
            >
              <v-avatar
                size="54"
                :color="resolveStatisticsIconVariation(data.title).color"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ resolveStatisticsIconVariation(data.title).icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  {{ data.title }}
                </p>
                <h3 class="text-xl font-weight-semibold">
                  <span>
                    {{ data.total }}
                  </span>
                </h3>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-col
              cols="2"
              class="mr-10"
            >
              <v-btn
                color="primary"
                outlined
                class="mb-5"
                @click="showCreditCustomerAccountDialog = true"
              >
                <v-icon
                  left
                  size="22"
                >
                  {{ icons.mdiCash100 }}
                </v-icon>
                Credit Customer
              </v-btn>
            </v-col>
            <credit-customer-account
              :show-dialog="showCreditCustomerAccountDialog"
              :reseller-id="resellerID"
              @close="showCreditCustomerAccountDialog = false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <revenue-bar-graph
              :title="'Revenue'"
              :is-revenue-loading="isRevenueBarGraphLoading"
              :revenue-bar-graph-data="revenueBarGraphData"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <unresolved-transactions-card :reseller-id="resellerID" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  mdiAccountMultipleOutline, mdiTableLarge, mdiDotsVertical, mdiTable, mdiCashMultiple, mdiEyeOff,
} from '@mdi/js'
import StatisticsShimmer from '@/components/partials/shimmers/StatisticsShimmer.vue'
import RevenueBarGraph from '@/views/statistics/transactions/components/RevenueBarGraph.vue'
import UnresolvedTransactionsCard from '@/views/dashboard/UnresolvedTransactionsCard.vue'
import CreditCustomerAccount from '@/components/dialogs/CreditCustomerAccount.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    StatisticsShimmer,
    RevenueBarGraph,
    UnresolvedTransactionsCard,
    CreditCustomerAccount,
  },
  mixins: [hasPermission],
  data() {
    return {
      resellerID: this.$route.params.id,
      statisticsData: [],
      statisticsDataLoading: true,
      showRevenue: false,
      isRevenueBarGraphLoading: true,
      showCreditCustomerAccountDialog: false,
      revenueBarGraphData: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          categories: [],
        },
        series: [{
          name: 'Revenue (Ksh)',
          data: [],
        }],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  setup() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Customers') return { icon: mdiAccountMultipleOutline, color: 'success' }
      if (data === 'Meters') return { icon: mdiTable, color: 'warning' }
      if (data === 'Main Meters') return { icon: mdiTableLarge, color: 'error' }
      if (data === 'Revenue') return { icon: mdiCashMultiple, color: 'info' }

      return { icon: mdiAccountMultipleOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiAccountMultipleOutline,
        mdiTable,
        mdiTableLarge,
        mdiCashMultiple,
        mdiEyeOff,
      },
    }
  },
  mounted() {
    this.getStatistics()
    this.getRevenueOfBarChart()
  },
  methods: {
    getStatistics() {
      this.statisticsDataLoading = true
      axios
        .get(`statistics/resellers/general/${this.resellerID}`)
        .then(response => {
          this.statisticsData = [
            {
              title: 'Customers',
              total: response.data.users,
              route: 'customers',
            },
            {
              title: 'Main Meters',
              total: response.data.main_meters,
              route: null,
              href: '#main-meters',
            },
            {
              title: 'Meters',
              total: response.data.meters,
              route: 'meters',
            },
            {
              title: 'Revenue',
              total: response.data.revenue,
            },
          ]
          this.statisticsDataLoading = false
        })
        .catch(error => {
          this.statisticsDataLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getRevenueOfBarChart() {
      this.isRevenueBarGraphLoading = true
      axios
        .get(`statistics/monthly-revenue?reseller_id=${this.resellerID}`)
        .then(response => {
          const { data } = response
          const chartLabels = []
          const chartValues = []

          data.forEach(element => {
            chartValues.push(element.value)
            chartLabels.push(element.name)
          })
          const ref = this

          this.revenueBarGraphData = {
            ...this.revenueBarGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'Revenue',
                data: chartValues,
              }],
              yaxis: {
                title: {
                  text: 'Amount (Ksh)',
                },
              },
              tooltip: {
                y: {
                  formatter(val) {
                    return `Ksh ${ref.formatCurrency(val)}`
                  },
                },
              },
            },
          }
          this.isRevenueBarGraphLoading = false
        })
        .catch(error => {
          this.isRevenueBarGraphLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
    formatCurrency(value) {
      return this.$options.filters.formatCurrency(value)
    },
  },
}
</script>
