<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Assign transaction to customer</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="assignTransaction()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="8"
                  class="mb-2"
                >
                  <v-autocomplete
                    v-model="form.user_id"
                    :items="users"
                    :loading="isUsersLoading"
                    :search-input.sync="users_search"
                    :filter="filterObject"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="id"
                    cache-items
                    hide-no-data
                    label="Customer name"
                    placeholder="Search customer name or account number"
                    :rules="[
                      v => !!v || 'Customer name is required',
                    ]"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for customer
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      {{ data.item.name }} - {{ data.item.account_number }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar class="primary font-weight-light white--text">
                        {{ data.item.name.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.account_number"></v-list-item-subtitle>
                        <br />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <small
                    v-show="form.errors.has('user_id')"
                    class="validation-error"
                  >{{
                    form.errors.get('user_id')
                  }}</small>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-select
                    v-model="form.account_type"
                    :items="account_types"
                    item-text="name"
                    item-value="id"
                    label="Account type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    resellerId: {
      type: String,
      default: '',
    },
    showDialog: {
      type: Boolean,
    },
  },
  data: () => ({
    form: new Form({
      id: '',
      user_id: '',
      account_type: 1,
    }),
    account_types: [
      {
        id: 1,
        name: 'Main',
      },
      {
        id: 2,
        name: 'Connection Fee',
      },
    ],
    stations: [],
    types: [],
    users: [],
    isUsersLoading: false,
    isStationsLoading: true,
    isTypesLoading: true,
    action: 'Assign',
    users_search: null,
    month_menu: false,
    bill_due_at_menu: false,
  }),
  computed: {
    meters_url() {
      return 'meters'
    },
    showAutomaticMeterFields() {
      return this.form.mode === 1
    },
  },
  watch: {
    users_search(value) {
      this.getUsers(value)
    },
    showDialog(value) {
      if (value && this.users.length === 0) {
        this.getUsers()
      }
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || item.account_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    getUsers: _.debounce(function (value) {
      this.isUsersLoading = true
      axios
        .get(`users?searchByNameAndAccountNo=${value}&reseller_id=${this.resellerId}`)
        .then(response => {
          this.users = response.data.data
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    assignTransaction() {
      this.form.id = this.id
      this.form
        .post('unresolved-transactions')
        .then(() => {
          this.$notification.success('Transaction assigned successfully')
          this.$emit('close')
          this.$emit('transaction-assigned')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
